/** Dont add anything here!!! 
    All themes and styles will be handles using material ui themes
  **/

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;

}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    background-color: #f4f6f8;
    height: 100%;
    
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
    font-family: "Montserrat", sans-serif;
}


/*Invoice Styles*/
.invoice-box {
    max-width: 1000px;
    margin: auto;
    padding: 50px;
    font-size: 15px;
    line-height: 1.5;
    font-family: 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
    color: rgb(0, 0, 0);
}

.invoice-box table {
    width: 100%;
    line-height: inherit;
    text-align: left;
}

.invoice-box table td {
    font-size: 12;
    vertical-align: top;
}

/* .invoice-box table tr td:last-child {
    text-align: left;
} */

.invoice-box table tr.top table td {
    padding-bottom: 10px;
}

.invoice-box table tr.top table td.title {
    font-size: 20px;
    line-height: 20px;
    color: #333;
}

.invoice-box table tr.information table td {
    padding-bottom: 15px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box table tr.details td {
    padding-bottom: 10px;
}

.invoice-box table tr.item td{
    border-bottom: 0.5px solid #eee;
}

.invoice-box table tr.item.last td {
    border-bottom: none;
}

.invoice-box table tr.total td:nth-child(2) {
    /*border-top: 2px solid #eee;*/
    font-weight: bold;
}

@media only screen and (max-width: 600px) {
    .invoice-box table tr.top table td {
        width: 100%;
        display: block;
        text-align: center;
    }
    
    .invoice-box table tr.information table td {
        width: 100%;
        display: block;
        text-align: center;
    }
}

/** RTL **/
.rtl {
    direction: rtl;
    font-family: Tahoma, 'Helvetica Neue', 'Helvetica', Helvetica, Arial, sans-serif;
}

.rtl table {
    text-align: right;
}

.rtl table tr td:nth-child(2) {
    text-align: left;
}

.invoice-logo{
    width:100%; max-width:150px;
}
.show-white-space {
    white-space: pre-wrap;
}
@media print {
    html, body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }
  
@page {
    size: auto;
    margin:0mm;
}

/* For Chrome and Edge*/
::-webkit-scrollbar {
    width: 6px
}

::-webkit-scrollbar-track {
    background: #546e7a;
    border-radius: 6px
}

::-webkit-scrollbar-thumb {
    background: rgba(243, 181, 107, 0.9);
    border-radius: 6px
}

/* For firefox*/
html{
    scrollbar-color: rgba(243, 181, 107, 0.9) #546e7a;
    scrollbar-width: thin;
}

.MuiPickersInlineWrapper-popoverPaper{
    width: fit-content !important
}

span.MuiButtonBase-root.MuiTableSortLabel-root.MuiTableSortLabel-active {
    color: rgba(255,255,255,1);
}

.MuiTableSortLabel-root:hover {
    color: rgba(243, 181, 107, 1)!important;
}

.GroupBatchContainer .MuiDialog-paperWidthSm{
    max-width: max-content;
}
@media print {
    .reportPdfContainer {
      width: calc(100% - 20mm) !important;
      padding-right: 10mm !important;
      padding-left: 10mm !important;
      page-break-inside: auto;
      overflow: visible !important;
    }

    .reportPdfContainerABWeeklyReport {
        width: calc(100% - 20mm) !important;
        padding-left: 10mm !important;
        page-break-inside: auto;
        overflow: visible !important;
    }
    .reportPdfContainerABWeeklyReportTable {
        padding-right: 10mm !important;
    }
}